<template>
  <mex-dialog
    :showDialog="showAddMacAddressToBlocklistDialog"
    dialogTitle="Add MAC address to blocklist"
    width="50%"
  >
    <template v-slot:dialog-content>
      <v-overlay v-if="macAddressAdding">
        <mex-sperm-spinner spinnerText="Adding MAC address" />
      </v-overlay>
      <v-row justify="center">
        <v-col cols="auto">
          <mex-p
            fontSize="h6"
            alignment="center"
            content="If a MAC address is added to the block list, all incoming requests that provide this MAC address in the credentials are blocked. Only valid MAC addresses can be added to the blocklist. Please give a meaningful description why you want to block this MAC address."
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-text-field
            v-model="macAddress"
            hide-details
            label="MAC address"
            outlined
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-text-field
            v-model="description"
            hide-details
            label="Description"
            outlined
          />
        </v-col>
      </v-row>
    </template>
    <template slot="dialog-actions">
      <v-row justify="center">
        <v-col cols="auto">
          <mex-btn content="Add MAC address" icon="mdi-plus" @click="addMacAddress" />
        </v-col>
        <v-col cols="auto">
          <mex-btn
            content="Discard"
            icon="mdi-delete"
            @click="discradAddMacAddressToBlocklistDialog"
          />
        </v-col>
      </v-row>
    </template>
  </mex-dialog>
</template>

<script>
import MexSpermSpinner from "../MedITEX_Vue_Components/MexComponents/MexSpermSpinner.vue";
import MacAddressBlocklistService from "../../services/macAddressBlocklist.service";

export default {
  name: "AddMacAddressToBlocklistDialog",
  components: { MexSpermSpinner },
  props: {
    showAddMacAddressToBlocklistDialog: {
      type: Boolean,
      default: false
    },
    macAddressPreSelection: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // data
      description: "",
      macAddress: "",
      // visualization
      macAddressAdding: false
    };
  },
  watch: {
    macAddressPreSelection(newValue) {
      if (newValue !== "") {
        this.macAddress = newValue;
      }
    }
  },
  mounted() {
    if (this.macAddressPreSelection) {
      this.macAddress = this.macAddressPreSelection;
    }
  },
  methods: {
    discradAddMacAddressToBlocklistDialog() {
      this.macAddressPreSelection = "";
      this.macAddress = "";
      this.description = "";
      this.$emit("reject");
    },
    addMacAddress() {
      this.macAddressAdding = true;
      MacAddressBlocklistService.addMacAddressToBlocklist(this.macAddress, this.description)
        .then(() => {
          this.$emit("confirm");
          this.$toast.success(this.macAddress + "  successfully added to blocklist");
          this.macAddressAdding = false;
          this.macAddress = "";
          this.description = "";
          this.macAddressPreSelection = "";
        })
        .catch(err => {
          this.macAddressAdding = false;
          this.$toast.error("Error while adding \"" + this.macAddress + "\" to blocklist: " + err.response.data);
        });
    }
  }
};
</script>

<style></style>
