<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="MAC Address Blocklist" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
      <mex-data-table
        dense
        :items-per-page="getTablePagination.defaultRowsPerPage"
        :data="macAddressBlocklist"
        :headers="macAddressBlocklistHeaders"
        tableClass="foreground"
        :footer-props="{
          'items-per-page-options': getTablePagination.rowsPerPage,
        }"
      >
        <template v-slot:item.remove="{item}">
          <mex-btn iconOnly icon="mdi-delete" @click="removeEntry(item)" />
        </template>
      </mex-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <mex-btn content="Add MAC address" icon="mdi-plus" @click="showAddMacAddressToBlocklistDialog = true" />
      </v-col>
    </v-row>
    <add-mac-address-to-blocklist-dialog
      :showAddMacAddressToBlocklistDialog="showAddMacAddressToBlocklistDialog"
      :macAddressPreSelection="macAddressPreSelection"
      @reject="closeAddMacAddressToBlocklistDialog"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import requiredPermissions from "../../requiredPermissions";
import AddMacAddressToBlocklistDialog from "../../components/LicSrvComponents/AddMacAddressToBlocklistDialog";
import MacAddressBlocklistService from "../../services/macAddressBlocklist.service";
import tablePagination from '../../config/tablePagination.config';

export default {
  name: "MacAddressBlocklist",
  components: {
    AddMacAddressToBlocklistDialog,
  },
  computed: {
    ...mapGetters("sysAuth", ["getUserPermissions"]),
    getTablePagination() {
      return tablePagination;
    }
  },
  data() {
    return {
      // data
      macAddressBlocklist: [],
      macAddressBlocklistHeaders: [
        {
          text: "MAC Address",
          align: "start",
          sortable: true,
          value: "macAddress"
        },
        {
          text: "Description",
          align: "start",
          sortable: true,
          value: "description"
        },
        {
          text: "created at",
          align: "start",
          sortable: true,
          value: "createdAt"
        },
        {
          text: "",
          align: "start",
          sortable: true,
          value: "remove"
        }
      ],
      hasPermission: false,
      showAddMacAddressToBlocklistDialog: false,
      macAddressPreSelection: "",
    };
  },
  created() {
    // REPLACE WITH REAL PERMISSIONS
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.systemSettings, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchMacAddressBlocklist();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  methods: {
    fetchMacAddressBlocklist() {
      MacAddressBlocklistService.getMacAddressBlocklist()
        .then((response) => {
          this.macAddressBlocklist = response.data;
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    removeEntry(item) {
      MacAddressBlocklistService.deleteMacAddressFromBlocklist(item.MacAddressBlocklistID)
        .then(() => {
          this.fetchMacAddressBlocklist();
          this.$toast.success("Successfully removed " + item.macAddress  + " from Blocklist");
        })
        .catch((err) => {
          this.$toast.error(err.response.data)
        })
    },
    closeAddMacAddressToBlocklistDialog() {
      this.showAddMacAddressToBlocklistDialog = false;
      this.fetchMacAddressBlocklist();
    }
  }
};
</script>

<style>
.extendedClinicRow {
  background-color: var(--v-secondary-base);
}
</style>
