import httpClient from "../httpClient/httpClient";

const MacAddressBlocklistService = {
  getMacAddressBlocklist() {
    return httpClient.get("api/get-mac-address-blocklist", {});
  },
  addMacAddressToBlocklist(macAddress, description) {
    return httpClient.post("api/add-mac-address-to-blocklist", { macAddress, description });
  },
  deleteMacAddressFromBlocklist(macAddressBlocklistID) {
    return httpClient.post("api/delete-mac-address-from-blocklist", { macAddressBlocklistID });
  }
};

export default MacAddressBlocklistService;
